import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { PerformanceResponse } from '../models/performance.model';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
// import { hash } from '@monorepo/tools/src/lib/tools/hash';

const getUrl = () => {
	const { sheriffRoot, localReportApi } = getConfig().env;

	return localReportApi ? `${localReportApi}/report` : `${sheriffRoot}/adminx-reporting/report`;
};

export const PerformanceApi = {
	performance(params: IPerformanceOptions): Promise<PerformanceResponse> {
		// const paramsHash = hash.encrypt(JSON.stringify(params));
		// let queryParams = '';
		// if (paramsHash) {
		// 	queryParams = `?ck=${paramsHash}`;
		// }
		// return ask.post<IPerformanceOptions>(`${getConfig().env.sheriffRoot}/adx/reporting/reportsApi/api/rp/repr`, params);
		return ask.post<IPerformanceOptions>(getUrl(), params).then(res => res.payload);
	},
	downloadCsv(params?: IPerformanceOptions): Promise<File | null> {
		return ask.downloadPost<IPerformanceOptions>(`${getUrl()}/download`, params || {});
	},
	generateCsv(params?: IPerformanceOptions): Promise<File | null> {
		return ask.post<IPerformanceOptions>(`${getUrl()}/generate-csv`, params || {});
	},
};

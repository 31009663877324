import { CampaignNameColumnConfig, CampaignIdColumnConfig } from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import { TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';

export const campaignPagesTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysHidden }),
		new CampaignIdColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysHidden }),
		new DimensionsColumnConfig({
			name: 'page',
			accessor: 'page',
			header: 'Page',
			visibility: ColumnVisibilityOptions.Visible,
			availableFilters: new TextFilterDefinition(),
			cell: ({ row }) => <OverflowColumn data={row.getValue('page')} />,
		}),
		...generateAdxReprPerformanceColumns(['opt_gain', 'opt_cost']),
	],
	includeChart: true,
	tableName: 'adxReprCampaignPagesPerformance', // must be uniq for each table report, it is the key for the local storage and export file
};

import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import { IdColumnConfig } from '../../../columns/adminx.columns';
import { BooleanFilterDefinition, TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { Tag, TagTypes } from '@monorepo/base/src/components/tag/tag';
import routes from '../../../../../routes/index.routes';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';

export const accountsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxAccountsReport',
		sort: [
			{
				column: 'name',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	tableState: {
		pagination: {
			pageIndex: 0,
			pageSize: 10,
		},
	},
	columns: [
		new IdColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new DimensionsColumnConfig({
			name: 'name',
			header: 'Name',
			accessor: 'name',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.AlwaysVisible,
			cell: ({ row }) => (
				<TableLink toEdit={routes.accounts.edit(row.getValue('id'))} to={routes.accounts.edit(row.getValue('id'))}>
					{row.getValue('name')}
				</TableLink>
			),
		}),
		new DimensionsColumnConfig({
			name: 'use_internal_pricing',
			header: 'Use Internal Pricing',
			accessor: 'use_internal_pricing',
			availableFilters: new BooleanFilterDefinition(),
			visibility: ColumnVisibilityOptions.AlwaysVisible,
			cell: ({ row }) => {
				const v = row.getValue('use_internal_pricing');
				return <Tag type={v ? TagTypes.Success : TagTypes.Error}>{v ? 'Yes' : 'No'}</Tag>;
			},
		}),
	],
	includeChart: false,
	tableName: 'adxReprAccounts',
};

import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';

export interface IAccount {
	id?: id;
	name?: string;
	use_internal_pricing?: boolean;
}

export type ICreateAccount = Omit<IAccount, 'id'>;

export class AccountsModel implements IAccount {
	id?: id;
	name?: string;
	use_internal_pricing?: boolean;

	constructor(account?: IAccount) {
		this.id = account?.id;
		this.name = account?.name;
		this.use_internal_pricing = account?.use_internal_pricing;

		makeAutoObservable(this);
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.name;
	}

	getUseInternalPricing() {
		return this.use_internal_pricing;
	}

	setId(id: id) {
		this.id = id;
	}

	setName(name: string) {
		this.name = name;
	}

	setUseInternalPricing(v: boolean) {
		this.use_internal_pricing = v;
	}
}

import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './buttons.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';

export enum Directions {
	FromRight = 'dirFromRight',
	FromLeft = 'dirFromLeft',
}

export enum ButtonDimensions {
	Large = 'large',
	Medium = 'medium',
	Small = 'small',
}

export interface IButton<T> {
	children?: string | JSX.Element | JSX.Element[] | ReactNode;
	onClick?: MouseEventHandler<T> | undefined;
	icon?: string | JSX.Element;
	iconColor?: string;
	iconSize?: string;
	iconDirection?: Directions;
	disabled?: boolean;
	id?: string;
	className?: string;
	isRotateIcon?: boolean;
	width?: string;
	textColor?: string;
	ref?: T;
	font?: IconFonts;
	style?: CSSProperties;
	dimension?: ButtonDimensions;
	debugProps?: IDebugProps;
}

export interface IHref extends IButton<HTMLAnchorElement> {
	to?: string;
	text?: boolean;
	state?: BetweenPagesStateProps;
	newWindow?: boolean;
	delSearchParams?: boolean;
}

interface IIconButton extends IButton<HTMLSpanElement> {
	children: string | JSX.Element;
	size?: string;
	color?: string;
	direction?: Directions;
	font?: IconFonts;
	noPadding?: boolean
}

function prepareButton<T>(props: IButton<T>) {
	const { className, textColor, width, disabled, onClick, id, style = {}, dimension = ButtonDimensions.Large, debugProps } = props;
	const _style: CSSProperties = { ...style };
	const { dataAttrs } = debugProps || {};

	if (width) {
		_style.width = width;
	}
	if (textColor) {
		_style.color = textColor;
	}

	const buttonProps = {
		disabled,
		onClick,
		id,
		style: _style,
		className: `${className ? className : ''} ${styles.baseButton} ${disabled ? styles.disabled : ''} ${styles[dimension]}`,
		...generateDataAttrs(dataAttrs),
	};
	return {
		buttonProps,
	};
}

const IconInButton = ({ children, className, color, size, direction, font, noPadding }: IIconButton) => {
	if (typeof children === 'string') {
		const dirClassName = noPadding
			? ''
			: direction === Directions.FromLeft ? styles.iconToTheLeft : styles.iconToTheRight;

		return (
			<Icon
				className={`${className} ${dirClassName}`}
				isMFP={true}
				size={size || '20px'}
				color={color}
				font={font}
			>
				{children}
			</Icon>
		);
	}

	return children;
};

export const IconButton = (props: IIconButton) => {
	const { children, size, color } = props;
	const { buttonProps } = prepareButton<HTMLSpanElement>(props);

	return (
		<Icon {...buttonProps} className={`${buttonProps.className}`} isMFP={true} size={size || '16px'} color={color}>
			{children}
		</Icon>
	);
};

export const PrimaryButton = (props: IButton<HTMLButtonElement>) => {
	const { icon, iconSize, iconDirection = Directions.FromLeft, children, isRotateIcon, debugProps } = props;
	const { buttonProps } = prepareButton<HTMLButtonElement>(props);
	const { dataAttrs } = debugProps || {};
	return (
		<button {...buttonProps} className={`${buttonProps.className} ${styles.primary}`} {...generateDataAttrs(dataAttrs)}>
			{iconDirection === Directions.FromLeft && icon ? (
				<IconInButton direction={iconDirection} className={isRotateIcon ? styles.rotateIcon : ''} size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
			{children}
			{iconDirection === Directions.FromRight && icon ? (
				<IconInButton direction={iconDirection} className={isRotateIcon ? styles.rotateIcon : ''} size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
		</button>
	);
};

export const SecondaryButton = (props: IButton<HTMLButtonElement>) => {
	const { icon, iconSize, iconColor, iconDirection = Directions.FromLeft, children, isRotateIcon, font, debugProps } = props;

	const { buttonProps } = prepareButton<HTMLButtonElement>(props);
	const { dataAttrs } = debugProps || {};

	return (
		<button {...buttonProps} className={`${buttonProps.className} ${styles.secondary}`} {...generateDataAttrs(dataAttrs)}>
			{iconDirection === Directions.FromLeft && icon ? (
				<IconInButton
					font={font}
					direction={iconDirection}
					className={isRotateIcon ? styles.rotateIcon : ''}
					color={iconColor}
					size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
			{children}
			{iconDirection === Directions.FromRight && icon ? (
				<IconInButton direction={iconDirection} className={isRotateIcon ? styles.rotateIcon : ''} color={iconColor} size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
		</button>
	);
};

export const PrimaryText = (props: IButton<HTMLButtonElement>) => {
	const { icon, iconDirection = Directions.FromLeft, children, isRotateIcon, debugProps } = props;
	const { buttonProps } = prepareButton<HTMLButtonElement>(props);
	const { dataAttrs } = debugProps || {};

	return (
		<button
			{...buttonProps}
			className={`${buttonProps.className} ${styles.textButton} ${styles.primaryText}`}
			{...generateDataAttrs(dataAttrs)}>
			{iconDirection === Directions.FromLeft && icon ? (
				<IconInButton direction={iconDirection} className={isRotateIcon ? styles.rotateIcon : ''}>
					{icon}
				</IconInButton>
			) : null}
			<span className={styles.text}>{children}</span>
			{iconDirection === Directions.FromRight && icon ? (
				<IconInButton direction={iconDirection} className={isRotateIcon ? styles.rotateIcon : ''}>
					{icon}
				</IconInButton>
			) : null}
		</button>
	);
};

export const SecondaryText = (props: IButton<HTMLButtonElement>) => {
	const { icon, iconDirection = Directions.FromLeft, children, isRotateIcon, iconColor, iconSize, font, debugProps } = props;

	const { buttonProps } = prepareButton<HTMLButtonElement>(props);
	const { onClick } = buttonProps;
	const { dataAttrs } = debugProps || {};

	return (
		<button
			{...buttonProps}
			className={`${buttonProps.className} ${styles.textButton} ${styles.secondaryText} ${!onClick ? styles.unclickable : ''}`}
			{...generateDataAttrs(dataAttrs)}>
			{iconDirection === Directions.FromLeft && icon ? (
				<IconInButton
					font={font}
					direction={iconDirection}
					className={isRotateIcon ? styles.rotateIcon : ''}
					color={iconColor}
					size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
			<span className={styles.text}>{children}</span>
			{iconDirection === Directions.FromRight && icon ? (
				<IconInButton
					font={font}
					direction={iconDirection}
					className={isRotateIcon ? styles.rotateIcon : ''}
					color={iconColor}
					size={iconSize}>
					{icon}
				</IconInButton>
			) : null}
		</button>
	);
};

export const PrimaryLink = (props: IHref) => {
	const {
		icon,
		iconDirection = Directions.FromLeft,
		children,
		to,
		onClick = undefined,
		disabled,
		text = false,
		state,
		newWindow,
	} = props;

	const { buttonProps } = prepareButton<HTMLAnchorElement>(props);

	return (
		<LinkWithParams
			{...buttonProps}
			to={to ? to : ''}
			state={state}
			onClick={disabled ? e => e.preventDefault() : onClick}
			target={newWindow ? '_blank' : undefined}
			className={`${buttonProps.className} ${text ? `${styles.textButton} ${styles.primaryText}` : styles.primary}`}>
			{iconDirection === Directions.FromLeft && icon ? <IconInButton direction={iconDirection}>{icon}</IconInButton> : null}
			<span className={styles.text}>{children}</span>
			{iconDirection === Directions.FromRight && icon ? <IconInButton direction={iconDirection}>{icon}</IconInButton> : null}
		</LinkWithParams>
	);
};

export const SecondaryLink = (props: IHref) => {
	const {
		textColor,
		icon,
		iconDirection = Directions.FromLeft,
		children,
		to,
		onClick = undefined,
		disabled,
		iconColor,
		text = false,
		iconSize,
		state,
		font,
		debugProps,
		newWindow,
		delSearchParams,
	} = props;

	const { buttonProps } = prepareButton<HTMLAnchorElement>(props);
	const { dataAttrs } = debugProps || {};
	return (
		<LinkWithParams
			{...generateDataAttrs(dataAttrs)}
			{...buttonProps}
			to={to ? to : ''}
			state={state}
			target={newWindow ? '_blank' : undefined}
			onClick={disabled ? e => e.preventDefault() : onClick}
			delSearchParams={delSearchParams}
			className={`${buttonProps.className} ${text ? `${styles.textButton} ${styles.secondaryText}` : styles.secondary}`}>
			{iconDirection === Directions.FromLeft && icon ? (
				<IconInButton noPadding={!text} direction={iconDirection} color={iconColor} size={iconSize} font={font}>
					{icon}
				</IconInButton>
			) : null}
			<span className={styles.text} style={{ color: textColor }}>
				{children}
			</span>
			{iconDirection === Directions.FromRight && icon ? (
				<IconInButton noPadding={!text} direction={iconDirection} color={iconColor} size={iconSize} font={font}>
					{icon}
				</IconInButton>
			) : null}
		</LinkWithParams>
	);
};

import { ColumnConfig, MetricsColumnConfig, MetricsCurrencyColumnConfig, MetricsPercentageRatioColumnConfig, TableType } from './columns';
import {
	currencyWithDecimalCellCreator,
	currencyWithDecimalValueFormatter,
	percentageRoundedCellCreator,
	percentageRoundedValueFormatter,
} from '../cells/metrics.cells';
import { ColumnVisibilityOptions } from './columns.types';

export class AuctionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions',
			accessor: 'auctions',
			header: 'Auctions',
			tooltip: 'Total number of auctions',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsValidColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_valid',
			accessor: 'auctions_valid',
			header: 'Auctions Valid',
			tooltip: 'Total number of valid auctions (based on feedback object, creative status code 1 and 79)',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsOkColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_ok',
			accessor: 'auctions_ok',
			header: 'Auctions Ok',
			tooltip: 'Total number of auctions that received status 1',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsOutbiddedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_outbidded',
			accessor: 'auctions_outbidded',
			header: 'Auctions Outbidded',
			tooltip: 'Total number of auctions that were outbidded by someone else',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsPublisherBlockedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_publisher_blocked',
			accessor: 'auctions_publisher_blocked',
			header: 'Auctions Publisher Blocked',
			tooltip: 'Total number of auctions that the publisher blocked us from appearing',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsBelowBidfloorColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_below_bidfloor',
			accessor: 'auctions_below_bidfloor',
			header: 'Auctions Below Bidfloor',
			tooltip: 'Total number of auctions that received a status that it was below bidfloor',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsDealsOutbiddedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_deals_outbidded',
			accessor: 'auctions_deals_outbidded',
			header: 'Auctions Deals Outbidded',
			tooltip: 'Total number of auctions that were outbidded by someone else in deal campaigns only',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsCreativeSizeIssuesColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_creative_size_issues',
			accessor: 'auctions_creative_size_issues',
			header: 'Auctions Creative Size Issues',
			tooltip: 'Total number of auctions that had some sort of a creative issue with them',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsInvalidOthersColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_invalid_others',
			accessor: 'auctions_invalid_others',
			header: 'Auctions Invalid Others',
			tooltip: 'Total number of invalid auctions in reasons not specific in specific group',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class AuctionsInvalidAllColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_invalid_all',
			accessor: 'auctions_invalid_all',
			header: 'Auctions Invalid All',
			tooltip: 'Total number of invalid auctions (status codes other than 1 and 79)',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterNoBidFoundColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_no_bid_found',
			accessor: 'filter_no_bid_found',
			header: 'Filter No Bid Found',
			tooltip:
				"The amount of times the campaign didn't bid on requests because either the bid_key was not supported OR the model didn't send a bid",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterBelowBidfloorColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_below_bidfloor',
			accessor: 'filter_below_bidfloor',
			header: 'Filter Below Bidfloor',
			tooltip:
				"The amount of times the campaign didn't bid on requests because the final bid was below the bidfloor that the publisher was willing to accept",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterTrashColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_trash',
			accessor: 'filter_trash',
			header: 'Filter Trash',
			tooltip:
				"The amount of times the campaign didn't bid on requests because the cache_key of the request was in the trash (or closed in snapshot), meaning the system learned that these placements are not good (or there was some sort of error)",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterUserColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_user',
			accessor: 'filter_user',
			header: 'Filter User',
			tooltip:
				"The amount of times the campaign didn't bid on requests because the user already has the product or the user cap has reached",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterBudgetColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_budget',
			accessor: 'filter_budget',
			header: 'Filter Budget',
			tooltip: "The amount of times the campaign didn't bid on requests because the campaign or domain has reached the daily budget",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterCreativeColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_creative',
			accessor: 'filter_creative',
			header: 'Filter Creative',
			tooltip:
				"The amount of times the campaign didn't bid on requests because of any issue with the creative itself (no relevant size, publisher blocked it, resource type, disapproved creatives, etc.)",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterTargetingColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_targeting',
			accessor: 'filter_targeting',
			header: 'Filter Targeting',
			tooltip:
				"The amount of times the campaign didn't bid on requests because there was no relevant campaign that was interested in this targeting (for example if traffic is Ubuntu and we have no campaign targeting Ubuntu it will be counted). It can also show this if a particular domain is blacklisted for a specific campaign",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FilterOthersColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_others',
			accessor: 'filter_others',
			header: 'Filter Others',
			tooltip:
				"The amount of times the campaign didn't bid on requests because of reasons not explicitly mentioned in other filter groups as they rarely occur. This is the current list of statuses that counted here: coalesce(creative_type, 0) + coalesce(blocked_advertiser, 0) + coalesce(url_keyword, 0) + coalesce(deal, 0) + coalesce(publisher_settings_excluded_creative, 0) + coalesce(publisher_settings_excluded_campaign, 0) + coalesce(iab_blocked_category, 0) + coalesce(blacklisted_bid_key, 0) + coalesce(non_whitelisted_bid_key, 0) + coalesce(paused_hours, 0) + coalesce(frequency_cap_click, 0) + coalesce(not_enough_traffic_pubtag, 0) + coalesce(no_active_targets, 0) + coalesce(ignored_tag, 0) + coalesce(empty_cache_key, 0) + coalesce(preferred_deal_creative_size, 0) + coalesce(creative_blocked_by_publisher, 0) + coalesce(bad_request_others_do_not_bid, 0) + coalesce(daily_max_loss, 0) + coalesce(native_ad_long_title, 0) + coalesce(native_required_image_is_missing, 0) + coalesce(native_required_data_is_missing, 0) + coalesce(native_ad_long_data, 0) + coalesce(native_ad_content_is_missing, 0) + coalesce(native_asset_not_supported, 0) + coalesce(unloaded_creative, 0) + coalesce(excluded_connection_type, 0) + coalesce(bad_isp, 0) + coalesce(env_type, 0) + coalesce(seller_type, 0) + coalesce(interstitial_targeting, 0) + coalesce(blacklisted_size, 0) + coalesce(non_whitelisted_size, 0) + coalesce(daily_campaign_max_loss_domain, 0) + coalesce(low_ctr_value, 0) + coalesce(daily_max_sales, 0)",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class WinsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'wins',
			accessor: 'wins',
			header: 'Wins',
			tooltip: 'The total number of times our auctions won',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class ClicksColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'clicks',
			accessor: 'clicks',
			header: 'Clicks',
			tooltip: 'The total number of clicks that occured in a segment',
			aggregateWhenSegment: true,
			...params,
		});
	}
}
export class ImpressionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'impressions', accessor: 'impressions', header: 'Impressions', aggregateWhenSegment: true, ...params });
	}
}

export class ConversionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'conversions', accessor: 'conversions', header: 'Conversions', aggregateWhenSegment: true, ...params });
	}
}

export class CostColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cost',
			accessor: 'cost',
			header: 'Cost',
			tooltip: 'The total cost of a particular segment',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class ConversionValueColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'conversion_value', accessor: 'conversionValue', header: 'Revenue', aggregateWhenSegment: true, ...params });
	}
}

export class WinRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'win_rate', accessor: 'winRate', header: 'Win rate', ...params });
	}
}

export class RoasColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'roas',
			accessor: 'roas',
			header: 'ROAS',
			tooltip: 'payout / cost, Return On Ad Spent (a.k.a ROI) based on pixel values that were sent to us',
			graphFormatter: percentageRoundedValueFormatter,
			footerFormatter: percentageRoundedValueFormatter,
			cell: percentageRoundedCellCreator('roas'),
			...params,
		});
	}
}

export class CtrColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'ctr', accessor: 'ctr', header: 'CTR', ...params });
	}
}

export class CvrColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'cvr', accessor: 'Cvr', header: 'CVR', ...params });
	}
}

export class LpCvrColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'lp_cvr', accessor: 'lpCvr', header: 'CVR', ...params });
	}
}

export class CpcColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpc',
			accessor: 'cpc',
			header: 'CPC',
			tooltip: "Not really avg, but just a cost / clicks on whatever segment you're looking at",
			cell: currencyWithDecimalCellCreator('cpc'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			...params,
		});
	}
}

export class CpmColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpm',
			accessor: 'cpm',
			header: 'CPM',
			cell: currencyWithDecimalCellCreator('cpm'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			tooltip: 'Cost / wins * 1000',
			...params,
		});
	}
}

export class CalcPayoutColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'calc_payout',
			accessor: 'calc_payout',
			header: 'Calc Payout',
			tooltip:
				'The calculated payout that received. It used a mechanism that was written by Alon that tries to understand if a particular domain is worth more than others',
			graphFormatter: percentageRoundedValueFormatter,
			...params,
		});
	}
}

export class GainColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'gain',
			accessor: 'gain',
			header: 'Gain',
			tooltip: 'payout - cost, how much did we earned in a segment',
			...params,
		});
	}
}

export class OptGainColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'opt_gain',
			accessor: 'optGain',
			header: 'Opt. Gain',
			tooltip:
				'Assuming out cost was optimal, meaning we would have bidded by the minimum bid to win (based on feedback received), what would our gain be then',
			...params,
		});
	}
}

export class OptCostColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'opt_cost',
			accessor: 'optCost',
			header: 'Opt. Cost',
			tooltip:
				'How much we would have paied if we would have bidded by the minimum bid to win (based on the feedback event received)',
			...params,
		});
	}
}

export class AvgBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'avg_bid_floor',
			accessor: 'avgBidFloor',
			header: 'Avg. bid floor',
			tooltip: 'The avg. bidfloor in a segment',
			...params,
		});
	}
}

export class AvgBidToWinColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'avg_bid_to_win', accessor: 'avgBidToWin', header: 'Avg. bid to win', ...params });
	}
}

export class MaxBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'max_bid_floor', accessor: 'maxBidFloor', header: 'Max bid floor', ...params });
	}
}

export class MaxBidToWinColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'max_bid_to_win', accessor: 'maxBidToWin', header: 'Max bid to win', ...params });
	}
}

export class MinBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'min_bid_floor', accessor: 'minBidFloor', header: 'Min bid floor', ...params });
	}
}

export class CPAColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpa',
			accessor: 'cpa',
			header: 'CPA',
			cell: currencyWithDecimalCellCreator('cpa'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			...params,
		});
	}
}

export class SpmColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'spm',
			accessor: 'spm',
			header: 'SPM',
			tooltip: 'Sales / wins * 1000',
			...params,
		});
	}
}

export class TotalMoneyEarnColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'total_money_earn',
			accessor: 'total_money_earn',
			header: 'Total Earn',
			tooltip:
				params.tableType === TableType.Advertiser
					? 'The total money an advertiser earned so far'
					: "The total revenue the campaign did in it's history",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class TotalMoneySpentColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'total_money_spent',
			accessor: 'total_money_spent',
			header: 'Total Spent',
			tooltip:
				params.tableType === TableType.Advertiser
					? 'The total money an advertiser spent so far'
					: "The total spend the campaign did in it's history",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class DailyMoneySpentColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'daily_money_spent',
			accessor: 'daily_money_spent',
			header: 'Daily Spent',
			tooltip:
				params.tableType === TableType.Advertiser
					? 'The total money an advertiser spent so far today'
					: 'The amount of money the campaign has spent today',
			...params,
		});
	}
}

export class DailyMoneyEarnColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'daily_money_earn',
			accessor: 'daily_money_earn',
			header: 'Daily Earn',
			tooltip:
				params.tableType === TableType.Advertiser
					? 'The total money an advertiser earned so far today'
					: 'The amount of money the campaign has earned today',
			...params,
		});
	}
}

export class AutctionsValidRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_valid_rate',
			accessor: 'auctions_valid_rate',
			header: 'Auctions Valid Rate',
			tooltip: 'auctions_valid / auctions_all, The rate of valid auctions from what we auctioned',
			...params,
		});
	}
}

export class BillableWinRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'billable_win_rate',
			accessor: 'billable_win_rate',
			header: 'Billable Win Rate',
			tooltip:
				' wins / auctions_valid. How many times did we win based only on times we actually were billed (wins). Represents growth potential in a more accurate way than actual_win_rate',
			...params,
		});
	}
}

export class ActualWinRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'actual_win_rate',
			accessor: 'actual_win_rate',
			header: 'Actual Win Rate',
			tooltip: 'auctions_ok / auctions_valid. How many times we actually won when we bidded based on feedback object',
			...params,
		});
	}
}

export class BidRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'bid_rate',
			accessor: 'bid_rate',
			header: 'Bid Rate',
			tooltip:
				'auctions_all / requests_fit_targeting. How many times we wanted to bid on requests divided only by requested that had at least 1 campaign that meet the targeting (if a perticular domain had 99% Ubuntu traffic, the bid_rate would reflect how much users we wanted from those that were relevant instead of giving a 0.01% bid rate)',
			...params,
		});
	}
}

export class AvgCtrColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'avg_ctr',
			accessor: 'avg_ctr',
			header: 'Avg CTR',
			tooltip: "Not really avg, but just a clicks / wins on whatever segment you're looking at",
			...params,
		});
	}
}

export class CalcRoasColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'calc_roas',
			accessor: 'calc_roas',
			header: 'Calc ROAS',
			tooltip: 'calc_payout / cost, Return On Ad Spent (a.k.a ROI) based on pixel values that we calculated for WebApps (Alon)',
			graphFormatter: percentageRoundedValueFormatter,
			...params,
		});
	}
}

export class ValidAuctionsRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'valid_auctions_rate',
			accessor: 'valid_auctions_rate',
			header: 'Valid Auctions Rate',
			tooltip:
				' auctions_valid / auctions_all. Basically tries to say how many auctions where considered valid among all the auctions',
			...params,
		});
	}
}

export class AuctionsOutbiddedRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_outbidded_rate',
			accessor: 'auctions_outbidded_rate',
			header: 'Auctions Outbidded Rate',
			tooltip: 'auctions_outbidded / auctions_all, The rate of outbidded auctions',
			...params,
		});
	}
}

export class AuctionsPublisherBlockedRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_publisher_blocked_rate',
			accessor: 'auctions_publisher_blocked_rate',
			header: 'Auctions Publisher Blocked Rate',
			tooltip: 'auctions_publisher_blocked / auctions_all. The rate of times the pubisher blocked us from appearing',
			...params,
		});
	}
}

export class AuctionsBelowBidfloorRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_below_bidfloor_rate',
			accessor: 'auctions_below_bidfloor_rate',
			header: 'Auctions Below Bidfloor Rate',
			tooltip:
				" auctions_below_bidfloor / auctions_all. The rate of times the bid was below bidfloor (those that we didn't know were below bidfloor before we auctioned)",
			...params,
		});
	}
}

export class AuctionsDealsOutbiddedRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_deals_outbidded_rate',
			accessor: 'auctions_deals_outbidded_rate',
			header: 'Auctions Deals Outbidded Rate',
			tooltip: ' auctions_deals_outbidded / auctions_all. The rate of times we were outbidded in deal based campaigns only',
			...params,
		});
	}
}

export class AuctionsCreativeSizeIssuesRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_creative_size_issues_rate',
			accessor: 'auctions_creative_size_issues_rate',
			header: 'Auctions Creative Size Issues Rate',
			tooltip:
				'auctions_creative_size_issues / auctions_all. The rate of times our auctions were disqualified because of creative issues',
			...params,
		});
	}
}

export class AuctionsInvalidOthersRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_invalid_others_rate',
			accessor: 'auctions_invalid_others_rate',
			header: 'Auctions Invalid Others Rate',
			tooltip:
				'auctions_invalid_others / auctions_all. The rate of times our auctions were disqualified due to various reasons not explicitly counted in other columns',
			...params,
		});
	}
}

export class FilterNoBidFoundRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_no_bid_found_rate',
			accessor: 'filter_no_bid_found_rate',
			header: 'Filter No Bid Found Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the no bid reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterBelowBidfloorRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_below_bidfloor_rate',
			accessor: 'filter_below_bidfloor_rate',
			header: 'Filter Below Bidfloor Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the below bid floor reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterTrashRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_trash_rate',
			accessor: 'filter_trash_rate',
			header: 'Filter Trash Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the trash reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterUserRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_user_rate',
			accessor: 'filter_user_rate',
			header: 'Filter User Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the user reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterBudgetRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_budget_rate',
			accessor: 'filter_budget_rate',
			header: 'Filter Budget Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the budget reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterCreativeRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_creative_rate',
			accessor: 'filter_creative_rate',
			header: 'Filter Creative Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the creative reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterTargetingRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_targeting_rate',
			accessor: 'filter_targeting_rate',
			header: 'Filter Targeting Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of the targeting reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class FilterOthersRateColumnConfig extends MetricsPercentageRatioColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_others_rate',
			accessor: 'filter_others_rate',
			header: 'Filter Others Rate',
			tooltip:
				"The % of times campaign didn't bid on a request because of any other reason, divided by requests_fit_targeting and not all requests",
			...params,
		});
	}
}

export class RequestsAllColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_all',
			accessor: 'requests_all',
			header: 'Requests All',
			tooltip: 'Total number of requests received from a particular page or domain regardless if relevant for us or not',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class RequestsFitTargetingColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_fit_targeting',
			accessor: 'requests_fit_targeting',
			header: 'Valid Requests',
			tooltip:
				"Total number of requests that at least 1 campaign was targeting ths user. It's our attempt to best indicate the amount of relevant traffic a particular domain or page had for various rate calculations",
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class RequestsOkColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_ok',
			accessor: 'requests_ok',
			header: 'Requests Ok',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class RequestsOkButNotSelectedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_ok_but_not_selected',
			accessor: 'requests_ok_but_not_selected',
			header: 'Requests Ok - Not Selected',
			tooltip: 'Total number of requests that were eligble for a campaign to bid on but not selected due to priority reasons',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PotentialTagsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_tags',
			accessor: 'potential_tags',
			header: 'Potential Tags',
			tooltip: 'The amount of Tag IDs that we received requests on and could have bid on',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class BiddedTagsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'bidded_tags',
			accessor: 'bidded_tags',
			header: 'Bidded Tags',
			tooltip: 'The amount of Tag ids that we bid on and actually won (creative status 1 or 79)',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PotentialFormatsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_formats',
			accessor: 'potential_formats',
			header: 'Potential Formats',
			tooltip: 'The total potential amount of formats we could have bidded on',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FormatsAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'formats_auctioned',
			accessor: 'formats_auctioned',
			header: 'Formats Auctioned',
			tooltip: 'The total amount of formats available based on auctions and not raw data, regardless if we actually used it or not',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class FormatsUsedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'formats_used',
			accessor: 'formats_used',
			header: 'Formats Used',
			tooltip:
				'The total number of formats that were used in our creative, if this number is lower than avg. it might indiciate that some campaign is missing creatives',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PotentialPublishersColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_publishers',
			accessor: 'potential_publishers',
			header: 'Potential Publishers',
			tooltip: 'The potential amount of publishers a domain or page had, regardless if we bid on it or not',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PublishersAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'publishers_auctioned',
			accessor: 'publishers_auctioned',
			header: 'Publishers Auctioned',
			tooltip: 'The number of publishers that we auctioned on in a specific domain or page',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PotentialPagesColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_pages',
			accessor: 'potential_pages',
			header: 'Potential Pages',
			tooltip: 'The amount of pages that we received requests on that we could have potentially bid on',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class PagesAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'pages_auctioned',
			accessor: 'pages_auctioned',
			header: 'Pages Auctioned',
			tooltip: 'The amount of pages that we bid on regardless if we won or not',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class TagsAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'tags_auctioned',
			accessor: 'tags_auctioned',
			header: 'Tags Auctioned',
			tooltip: 'The amount of tag IDs we bid on',
			aggregateWhenSegment: true,
			...params,
		});
	}
}

export class ClosedKeysColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'closed_keys',
			accessor: 'closed_keys',
			header: 'Closed Keys',
			tooltip:
				'This metric is problematic. Because keys can appear in several domains, a key might be counted several times. When looking at campaigns screen, it\'s somehow accurate, but if you\'re looking at this metric in the pages screen (or based on domains_pages agg table) the number can be much less accurate and you should treat it more of a "trend" metric and not an absolute number',
			...params,
		});
	}
}

export const generatePerformanceColumns = (): ColumnConfig[] => {
	return [
		new AuctionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsValidColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsOkColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsOutbiddedColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsPublisherBlockedColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsBelowBidfloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsDealsOutbiddedColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsCreativeSizeIssuesColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsInvalidOthersColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsInvalidAllColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CalcPayoutColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new SpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinRateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpcColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CPAColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptGainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptCostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AvgBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AvgBidToWinColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MaxBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MaxBidToWinColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MinBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ImpressionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
	];
};

export const generateAdxReprPerformanceColumns = (visibleColumns?: string[]): ColumnConfig[] => {
	return [
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsColumnConfig({ name: 'auctions_all', accessor: 'auctions_all', visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsValidColumnConfig(),
		new AuctionsOkColumnConfig(),
		new AuctionsOutbiddedColumnConfig(),
		new AuctionsPublisherBlockedColumnConfig(),
		new AuctionsBelowBidfloorColumnConfig(),
		new AuctionsDealsOutbiddedColumnConfig(),
		new AuctionsCreativeSizeIssuesColumnConfig(),
		new AuctionsInvalidOthersColumnConfig(),
		new AuctionsInvalidAllColumnConfig(),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({
			name: 'payout',
			accessor: 'payout',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The amount of money the campaign was payed based on pixels reported',
		}),
		new ConversionsColumnConfig({
			name: 'sales',
			accessor: 'sales',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The total number of sales that occurred in a segment',
		}),
		new SpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ accessor: 'lp_cvr', visibility: ColumnVisibilityOptions.Visible }),
		new CpcColumnConfig(),
		new CPAColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpmColumnConfig(),
		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptGainColumnConfig({ accessor: 'opt_gain' }),
		new OptCostColumnConfig({ accessor: 'opt_cost' }),
		new AvgBidToWinColumnConfig({ accessor: 'avg_bid_to_win' }),
		new BillableWinRateColumnConfig(),
		new ActualWinRateColumnConfig(),
		new BidRateColumnConfig(),
		new AvgCtrColumnConfig(),
		new CalcRoasColumnConfig(),
		new CalcPayoutColumnConfig(),
		new ValidAuctionsRateColumnConfig(),
		new AuctionsOutbiddedRateColumnConfig(),
		new AuctionsPublisherBlockedRateColumnConfig(),
		new AuctionsBelowBidfloorRateColumnConfig(),
		new AuctionsDealsOutbiddedRateColumnConfig(),
		new AuctionsCreativeSizeIssuesRateColumnConfig(),
		new AuctionsInvalidOthersRateColumnConfig(),
		new FilterNoBidFoundRateColumnConfig(),
		new FilterBelowBidfloorRateColumnConfig(),
		// server should support but throws error
		// new FilterNoBidFoundColumnConfig(),
		// new FilterBelowBidfloorColumnConfig(),
		// new FilterTrashColumnConfig(),
		// new FilterUserColumnConfig(),
		// new FilterBudgetColumnConfig(),
		// new FilterCreativeColumnConfig(),
		// new FilterTargetingColumnConfig(),
		// new FilterOthersColumnConfig(),
		// new AutctionsValidRateColumnConfig(),
		new FilterTrashRateColumnConfig(),
		new FilterUserRateColumnConfig(),
		new FilterBudgetRateColumnConfig(),
		new FilterCreativeRateColumnConfig(),
		new FilterTargetingRateColumnConfig(),
		new FilterOthersRateColumnConfig(),
		new RequestsAllColumnConfig(),
		new RequestsFitTargetingColumnConfig(),
		new RequestsOkButNotSelectedColumnConfig(),
		new PotentialTagsColumnConfig(),
		new BiddedTagsColumnConfig(),
		new PotentialFormatsColumnConfig(),
		new FormatsAuctionedColumnConfig(),
		new FormatsUsedColumnConfig(),
		new PotentialPublishersColumnConfig(),
		new PublishersAuctionedColumnConfig(),
		new PotentialPagesColumnConfig(),
		new PagesAuctionedColumnConfig(),
		new TagsAuctionedColumnConfig(),
		new ClosedKeysColumnConfig(),
	].map(c => {
		if (visibleColumns?.includes(c.accessor)) {
			c.visibility = ColumnVisibilityOptions.Visible;
		}

		return c;
	});
};

import { BaseRoutes } from '@monorepo/tools/src/lib/routes/base.routes';
import { AuthRoutes } from './auth.routes';
import { GeneralRoutes } from './general.routes';
import { AccountsRoutes } from './accounts.routes';
import { KeysManagementRoutes } from './keys-management.routes';
import { BidderRoutes } from './bidder.routes';

const routes = {
	reports: new BaseRoutes('reports'),
	campaigns: new BaseRoutes('campaigns'),
	preferences: new BaseRoutes('preferences'),
	creatives: new BaseRoutes('creatives'),
	creativeGroups: new BaseRoutes('creative-groups'),
	advertisers: new BaseRoutes('advertisers'),
	campaignGroups: new BaseRoutes('campaign-groups'),
	tests: new BaseRoutes('tests'),
	deals: new BaseRoutes('deals'),
	domains: new BaseRoutes('domains'),
	jobs: new BaseRoutes('jobs'),
	tools: new BaseRoutes('tools'),
	bidder: new BidderRoutes(),
	auth: new AuthRoutes(),
	keysManagement: new KeysManagementRoutes(),
	accounts: new AccountsRoutes(),
	general: new GeneralRoutes(),
};

export default routes;

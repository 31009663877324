export class AccountsRoutes {
	baseRoute = '/accounts';

	index() {
		return this.baseRoute;
	}

	create() {
		return `${this.baseRoute}/create`;
	}

	edit(id: string) {
		return `${this.baseRoute}/${id}/edit`;
	}
}

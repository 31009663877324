import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { AccountsApi } from '../../apis/accounts.api';
import { HttpError } from '../../models/http-error.model';
import { AccountsModel, IAccount, ICreateAccount } from '../../models/account.model';

export class AccountsCrudStore extends BaseCrudStore<AccountsModel, ICreateAccount, IAccount, HttpError> {
	constructor() {
		super({
			apiLayer: AccountsApi,
			model: AccountsModel,
			errorModel: HttpError,
		});
	}

	public getCreateFormData(): Omit<IAccount, 'id'> {
		return this.getData();
	}

	public getEditFormData(): IAccount {
		return this.getData();
	}

	public isValid(): boolean {
		this.formStore.reset();
		const { name } = this.getData();

		if (!name) {
			this.formStore.addError(new FormError('name', 'Please provide a name'));
		}

		return this.formStore.getIsValid();
	}
}

import { getConfig } from '@monorepo/tools/src/lib/get-config';

export class AdminxReporterEndpoint {
	report() {
		const { sheriffRoot, localReportApi } = getConfig().env;

		return localReportApi ? `${localReportApi}/report` : `${sheriffRoot}/adminx-reporting/report`;
	}

	newReport() {
		return `${this.report()}/repr`;
	}
}

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { CampaignBidKeysFiles } from '../../../../modules/components/campaign-bid-keys-files/campaign-bid-keys-files';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { Card } from '@monorepo/base/src/components/card/card';
import { useTableStoreMetrics } from '../../../../modules/hooks/tools/use-repr-metrics';

export const CampaignBidKeys = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaignBidKeysPerformance } = campaignStore;
	const { campaignId } = useParams();

	const hiddenFilters = campaignId
		? [{ column: 'campaign_id', filterType: ReportFilterType.EQUALS, value: [campaignId], inverted: false }]
		: undefined;

	useTableStore({ reportTableStore: campaignBidKeysPerformance, hiddenFilters });

	const chartProps = useTableStoreMetrics(campaignBidKeysPerformance);

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Bid Keys</PagebarTitle>
				<PagebarActions>
					<CampaignBidKeysFiles />
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full>
				<Chart isLoading={campaignBidKeysPerformance.dataStore.getIsLoading()} {...chartProps} />
			</Card>

			<ReportTable tableStore={campaignBidKeysPerformance} />
		</Page>
	);
});

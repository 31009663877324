import { ReportFilter } from '../data-stores/old-report.types';
import { OnChangeFn, PaginationState, RowSelectionState, SortingState, VisibilityState } from '@tanstack/react-table';
import { ITableState } from '../../table-state.store';
import { TableStore } from './table.store';
import { AdminxReportStore } from '../data-stores/adx-repr.store';
import { IAdminxReporterParams, SortOrderEnum } from '../data-stores/adx-repr.types';
import { IDataType } from '../data-stores/data-store.types';
import { ColumnConfig } from '../../columns/columns';

export interface IAdminxReportTableStoreParams {
	reportParams: Partial<IAdminxReporterParams>;
	columns: ColumnConfig[];
	tableState?: Partial<ITableState>;
	includeChart: boolean;
	tableName: string;
}

export class NewAdminxReportTableStore<T = IDataType> extends TableStore<T> {
	constructor({ reportParams, columns, includeChart, tableState = {}, tableName }: IAdminxReportTableStoreParams) {
		super({
			columns,
			tableState: {
				sorting: reportParams.sort?.[0]
					? [{ id: reportParams.sort[0].column, desc: reportParams.sort[0].sortOrder === SortOrderEnum.DESC }]
					: [],
				filters: reportParams.filters?.map(AdminxReportStore.convertFilterToStateFilter) ?? [],
				...tableState,
			},
			tableName,
			dataStore: new AdminxReportStore<T>({
				reportParams,
				includeChart,
				columns,
			}),
		});
	}

	getParamsFromState = (): Partial<IAdminxReporterParams> => {
		const { pagination, sorting, columnVisibility, filters } = this.tableState;
		const groupBys = this.columns
			.filter(
				col =>
					col.type !== 'actions' &&
					(col.isAlwaysVisible() ||
						col.isAlwaysHidden() ||
						col.type === 'data' ||
						(columnVisibility[col.accessor] && col.type !== 'metrics'))
			)
			.reduce((acc, col) => {
				acc.push(col.name, ...(col.linkTo || []));
				return acc;
			}, [] as string[]);
		const metrics = this.columns
			.filter(col => col.type === 'metrics' && (col.isAlwaysVisible() || col.isAlwaysHidden() || columnVisibility[col.accessor]))
			.reduce((acc, col) => {
				acc.push(col.name, ...(col.linkTo || []));
				return acc;
			}, [] as string[]);

		return {
			start: pagination.pageIndex * pagination.pageSize,
			limit: Math.max(pagination.pageSize, this.dataStore.reportParams.limit),
			sort: sorting.map(sort => ({ column: sort.id, sortOrder: sort.desc ? SortOrderEnum.DESC : SortOrderEnum.ASC })),
			groupBys: Array.from(new Set(groupBys)),
			metrics: Array.from(new Set(metrics)),
			segments: !this.tableState.isSpeedyModeOn && this.tableState.segment ? [this.tableState.segment] : [],
			filters: filters.map(AdminxReportStore.convertStateFilterToFilter),
		};
	};

	fetchReport = async (reportParams: Partial<IAdminxReporterParams>, appendData = false) => {
		const params = { ...this.getParamsFromState(), ...reportParams };

		await this.dataStore.fetchReport({ params, appendData });
	};

	onSortingChange: OnChangeFn<SortingState> = sorting => {
		const newSorting = typeof sorting === 'function' ? sorting(this.tableState.sorting) : sorting;
		if (this.dataStore.getTotalRows() <= (this.dataStore.getReportData?.() ?? []).length) {
			this.updateTableState({ sorting: newSorting });
			return;
		}
		if (newSorting.length === 0) {
			this.fetchReport({
				sort: JSON.parse(JSON.stringify(this.dataStore.defaultReportParams.sort)),
			}).then(() => {
				const defaultSorting = JSON.parse(JSON.stringify(this.dataStore.defaultReportParams.sort));
				this.updateTableState({ sorting: defaultSorting });
			});
			return;
		}
		const column = this.columns.find(col => col.accessor === newSorting[0]?.id);
		if (column) {
			this.fetchReport({
				sort: [{ column: column.name, sortOrder: newSorting[0].desc ? SortOrderEnum.DESC : SortOrderEnum.ASC }],
			}).then(() => {
				this.updateTableState({ sorting: newSorting });
			});
		}
	};

	onSegmentChange = (segment: string) => {
		this.updateTableState({ segment });
		this.fetchReport({});
	};

	onColumnVisibilityChange: OnChangeFn<VisibilityState> = columnVis => {
		const columnVisibility = typeof columnVis === 'function' ? columnVis(this.tableState.columnVisibility) : columnVis;
		const newGroupBysSet = new Set<string>();
		const newMetricsSelectionSet = new Set<string>();
		const oldGroupBysSet = new Set(this.dataStore.reportParams.groupBys);
		const oldMetricsSelectionSet = new Set(this.dataStore.reportParams.metrics);
		this.columns.forEach(col => {
			const columnsLinkedTo = col.linkTo || [];
			if (
				col.isAlwaysVisible() ||
				col.isAlwaysHidden() ||
				col.type === 'data' ||
				(columnVisibility[col.accessor] && col.type !== 'metrics')
			) {
				newGroupBysSet.add(col.name);
				columnsLinkedTo.forEach(val => newGroupBysSet.add(val));
			}
		});
		this.columns.forEach(col => {
			if (col.type === 'metrics' && (col.isAlwaysVisible() || col.isAlwaysHidden() || columnVisibility[col.accessor])) {
				newMetricsSelectionSet.add(col.name);
			}
		});

		const groupBys = Array.from(newGroupBysSet);
		const metrics = Array.from(newMetricsSelectionSet);
		const sorting = this.tableState.sorting;

		if (![...groupBys, ...metrics].includes(sorting[0]?.id)) {
			if (!sorting[0]) {
				sorting[0] = { id: '', desc: true };
			}

			if (metrics[0]) {
				sorting[0].id = metrics[0];
				sorting[0].desc = true;
			} else {
				sorting[0].id = groupBys[0];
				sorting[0].desc = false;
			}
		}

		// If groupBys or metrics have changed, fetch new report and reset row selection
		if (
			newGroupBysSet.size !== oldGroupBysSet.size ||
			newMetricsSelectionSet.size !== oldMetricsSelectionSet.size ||
			![...newGroupBysSet].every(x => oldGroupBysSet.has(x)) ||
			![...oldGroupBysSet].every(x => newGroupBysSet.has(x))
		) {
			this.fetchReport({
				groupBys,
				metrics,
				sort: sorting.map(sortItem => ({ column: sortItem.id, sortOrder: sortItem.desc ? SortOrderEnum.DESC : SortOrderEnum.ASC })),
			});
			this.updateTableState({ rowSelection: {}, sorting });
		}

		this.updateTableState({ columnVisibility, sorting });
	};

	onPaginationChange: OnChangeFn<PaginationState> = pagination => {
		const newPagination = typeof pagination === 'function' ? pagination(this.tableState.pagination) : pagination;
		this.updateTableState({ pagination: newPagination });
		const newStart = newPagination.pageIndex * newPagination.pageSize;
		const newLimit = newPagination.pageSize;
		const oldLimit = this.dataStore.reportParams.start + this.dataStore.reportParams.limit;
		if (newStart >= oldLimit || newLimit > oldLimit) {
			this.fetchReport(
				{
					start: newStart,
					limit: Math.max(newPagination.pageSize, this.dataStore.reportParams.limit),
				},
				true
			);
		}
	};

	onRowSelectionChange: OnChangeFn<RowSelectionState> = rowSelection => {
		const newRowSelection = typeof rowSelection === 'function' ? rowSelection(this.tableState.rowSelection) : rowSelection;
		this.updateTableState({ rowSelection: newRowSelection });
	};

	getSelectedRows = () => {
		const rowSelection = this.tableState.rowSelection;
		const selectedRowsTableId = Object.keys(rowSelection).filter(rowId => rowSelection[rowId]);
		return this.dataStore.getReportData().filter(row => selectedRowsTableId.includes(this.getRowId(row)));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getRowId = (row: any) => {
		let rowId = '';
		this.dataStore.reportParams.groupBys.forEach(groupBy => {
			const accessor = this.columns.find(col => col.name === groupBy)?.accessor;
			if (accessor && row[accessor]) {
				rowId += `${row[accessor]}_`;
			}
		});
		return rowId;
	};

	onFilterChange = (filters: ReportFilter[]) => {
		this.updateTableState({ filters });
		this.fetchReport({ filters: filters.map(AdminxReportStore.convertStateFilterToFilter) });
	};

	get dataStore(): AdminxReportStore<T> {
		return this._dataStore as AdminxReportStore<T>;
	}

	set dataStore(value: AdminxReportStore<T>) {
		this._dataStore = value;
	}
}

import { AccountsModel, IAccount, ICreateAccount } from '../models/account.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';

export const AccountsApi = {
	create: async (account: ICreateAccount, options?: IRequestOptions): Promise<AccountsModel> => {
		return ask.post<ICreateAccount>(endpoints.accounts.create(), account, options);
	},

	edit: (id: id, account: IAccount, options?: IRequestOptions): Promise<AccountsModel> => {
		return ask.patch<IAccount>(endpoints.creativeGroups.edit(id), account, options);
	},

	list: async (params?: URLSearchParams): Promise<AccountsModel[]> => {
		return ask.get(endpoints.accounts.list(), params);
	},

	get: async (id: id, params?: URLSearchParams, options?: IRequestOptions): Promise<AccountsModel> => {
		return ask.get(endpoints.accounts.get(id), params, options);
	},

	delete: async () => {
		throw new Error('Not implemented');
	},
};

import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useCampaignInputOptions } from '../../../hooks/apis/use-campaign-input-options';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Decisions } from '../../../enums/decisions';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';

export const AccountEditorForm = observer(() => {
	useCampaignInputOptions();

	const { accountsStore } = useStores<IAdminxStore>();
	const crudStore = accountsStore.getCrud();

	const formStore = crudStore.getFormStore();
	const account = crudStore.getData();

	return (
		<Fragment>
			<Card title="Details" debugProps={{ dataAttrs: [new DataAttribute('id', 'account_form')] }}>
				<Fragment>
					<Input
						value={account.name}
						placeholder="Name"
						onValue={v => {
							account.setName(v);
							formStore.clearError('name');
						}}
						required={true}
						error={formStore.getErrors().get('name')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'account_name')] }}
					/>

					<Spacer y={2} />

					<Toggle
						align="left"
						title="Use Internal Pricing?"
						label={account.getUseInternalPricing() ? Decisions.Yes : Decisions.No}
						onChange={v => account.setUseInternalPricing(v)}
						defaultValue={account.getUseInternalPricing()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'account_use_internal_pricing')] }}
					/>
				</Fragment>
			</Card>
		</Fragment>
	);
});

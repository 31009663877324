import { PaginationState, RowSelectionState, SortingState, VisibilityState } from '@tanstack/react-table';
import { ReportFilter } from './stores/data-stores/old-report.types';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { generateStorageKey } from '@monorepo/controlled/src/stores/helper';

export interface ITableState {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: ReportFilter[];
	columnVisibility?: VisibilityState;
	rowSelection: RowSelectionState;
	tableName: string;
	segment?: string;
	isSpeedyModeOn?: boolean;
}

export class TableState implements ITableState {
	pagination: PaginationState;
	sorting: SortingState;
	filters: ReportFilter[];
	segment?: string;
	columnVisibility: VisibilityState;
	rowSelection: RowSelectionState;
	tableName: string;
	isSpeedyModeOn?: boolean;

	constructor({
		pagination = { pageIndex: 0, pageSize: 20 },
		sorting = [],
		filters = [],
		columnVisibility = {},
		rowSelection = {},
		tableName,
		isSpeedyModeOn = false,
	}: ITableState) {
		this.pagination = pagination;
		this.sorting = sorting;
		this.filters = filters;
		this.columnVisibility = columnVisibility;
		this.rowSelection = rowSelection;
		this.tableName = tableName;
		this.isSpeedyModeOn = isSpeedyModeOn;
		makeAutoObservable(this);
		makePersistable(this, {
			name: generateStorageKey(`tbl-stt-${tableName}-v3`),
			properties: ['pagination', 'sorting', 'columnVisibility', 'filters', 'isSpeedyModeOn'],
			storage: window.localStorage,
		});
	}

	updateTableState(newTableState: Partial<ITableState>) {
		this.pagination = newTableState.pagination ?? this.pagination;
		this.sorting = newTableState.sorting ?? this.sorting;
		this.filters = newTableState.filters ?? this.filters;
		this.columnVisibility = newTableState.columnVisibility ?? this.columnVisibility;
		this.rowSelection = newTableState.rowSelection ?? this.rowSelection;
		this.isSpeedyModeOn = newTableState.isSpeedyModeOn ?? this.isSpeedyModeOn;
		this.segment = newTableState.segment ?? this.segment;
	}
}

import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Upload } from '@monorepo/base/src/components/form/upload/upload';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import styles from './campaign-bid-keys-files.module.scss';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IAdminxStore } from '../../stores';
import { useState } from 'react';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { CampaignsApi } from '../../apis/campaigns.api';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { getTheme } from '@monorepo/tools/src/lib/get-config';

const theme = getTheme();

export const CampaignBidKeysFiles = observer(() => {
	const { toastsStore } = useStores<IAdminxStore>();
	const { campaignId } = useParams();
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);

	if (!campaignId) {
		return null;
	}

	const downloadBidKeys = async () => {
		setIsDownloading(true);
		useDownload({
			httpStore: new HttpStore<undefined, File | null>({
				httpFunc: () => CampaignsApi.downloadBidKeys(campaignId),
			}),
			fileName: `${campaignId}_bidkeys_${new Date().toISOString().substring(0, 19).replace('T', ' ').replace(/:/g, '-')}`,
			extension: 'csv',
		})
			.catch(err => {
				toastsStore.addToast({ msg: 'Download Failed, try again later.', type: DispatchLogsTypes.Error });
				console.log(err);
			})
			.finally(() => {
				setIsDownloading(false);
			});
	};

	const onUpload = (file: File | FileList) => {
		setIsUploading(true);
		CampaignsApi.uploadBidKeys(campaignId, file)
			.then(() => {
				toastsStore.addToast({ msg: 'Upload Success', type: DispatchLogsTypes.Success });
			})
			.catch(() => {
				toastsStore.addToast({ msg: 'Upload Failed', type: DispatchLogsTypes.Error });
			})
			.finally(() => {
				setIsUploading(false);
			});
	};
	return (
		<div className={styles.wrapper}>
			<IndexLinerButton>
				<div>
					<SecondaryText
						className={styles.actions}
						icon={'download-04'}
						onClick={downloadBidKeys}
						iconColor={theme.actionLinerActionIconsColor}
						textColor={theme.gray500}
						disabled={isDownloading}
						iconSize={'18px'}>
						Download Campaign Bid Keys
					</SecondaryText>
					<BarLoader is={isDownloading} />
				</div>
			</IndexLinerButton>
			<IndexLinerButton>
				<div>
					<SecondaryText
						className={styles.actions}
						icon={'upload-04'}
						iconColor={theme.actionLinerActionIconsColor}
						textColor={theme.gray500}
						disabled={isUploading}
						iconSize={'18px'}>
						<Upload accept={'.csv'} onUpload={onUpload}>
							Upload Campaign Bid Keys
						</Upload>
					</SecondaryText>
					<BarLoader is={isUploading} />
				</div>
			</IndexLinerButton>
		</div>
	);
});

import { CreativeGroupModel, ICreativeGroupCreateForm, ICreativeGroupEditForm } from '../models/creative-group.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const CreativeGroupsApi = {
	list: (params?: URLSearchParams): Promise<CreativeGroupModel[]> => {
		return ask.get(endpoints.creativeGroups.list(), params);
	},
	create: (creativeGroup: ICreativeGroupCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<ICreativeGroupCreateForm>(endpoints.creativeGroups.create(), creativeGroup, options);
	},
	get: (id: id, params?: URLSearchParams): Promise<CreativeGroupModel> => {
		return ask.get(endpoints.creativeGroups.get(id), params);
	},
	edit: (id: id, creativeGroup: ICreativeGroupEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<ICreativeGroupEditForm>(endpoints.creativeGroups.edit(id), creativeGroup, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.creativeGroups.delete(id));
	},
};

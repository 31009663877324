import { makeAutoObservable, observable } from 'mobx';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { PerformanceResponse } from '../models/performance.model';
import { PerformanceApi } from '../apis/performance.api';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { filterPrototypesByProp } from '../components/filters-menu/filters/index';
import {
	DynamicReport,
	dynamicReportTableConfig,
} from '../components/report-table/configurations/old-report/dynamic-report/dynamic-report.config';
import { IRowProps, ReportTableStore } from '../components/report-table/stores/table-stores/old-report-table.store';

export enum ReportFiltersStoreKeys {
	Reports = 'reports',
}

export class ReportStore {
	settingsStore: SettingsStore;
	reportList = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	dynamicReport = new ReportTableStore<DynamicReport & IRowProps<DynamicReport>>(dynamicReportTableConfig);

	constructor(settingsStore: SettingsStore) {
		({
			httpFunc: PerformanceApi.performance,
			model: PerformanceResponse,
		});

		this.settingsStore = settingsStore;

		makeAutoObservable(this, {
			reportList: observable,
			metrics: observable,
		});
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(ReportFiltersStoreKeys).map((key: ReportFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const reportFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, reportFilterByKey);
		});
	}

	getReportList() {
		return this.reportList;
	}

	getMetrics() {
		return this.metrics;
	}
}

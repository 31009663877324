import { runInAction, observable, makeObservable, action } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';
import { ICrudAPI } from '@monorepo/tools/src/lib/interfaces/crud';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { IAskError } from '@monorepo/tools/src/lib/tools/ask/ask';
import { HttpError } from '@monorepo/adminx/src/modules/models/http-error.model';

// https://github.com/quarrant/mobx-persist-store/issues/79
// TODO - when mobx-persist-store fix this bug add private to this properties

interface IBaseCrudStoreOptions<T, TCreate, TEdit, TError> {
	apiLayer: ICrudAPI<T, TCreate, TEdit>;
	model: Constructable<T>;
	errorModel?: Constructable<TError>;
}

// T must has id and getId
export interface Id {
	id?: id;
	getId: () => id | undefined;
}

export abstract class BaseCrudStore<T extends Id, TCreate, TEdit, TError = IAskError> {
	formStore: FormStore = new FormStore();
	isLoading = false;
	isSuccess = false;
	apiLayer: ICrudAPI<T, TCreate, TEdit>;
	httpError: TError | null = null;
	data: T;
	originData: T;
	isLocalCache = false;
	model: Constructable<T>;
	errorModel?: Constructable<TError>;
	isSaved: boolean;
	isEdited: boolean;
	onError?(error: TError): void;

	constructor(options: IBaseCrudStoreOptions<T, TCreate, TEdit, TError>) {
		const { model, errorModel, apiLayer } = options;
		this.model = model;
		this.errorModel = errorModel;
		this.data = this.createInstance();
		this.originData = this.createInstance();
		this.apiLayer = apiLayer;
		this.isSaved = true;
		this.isEdited = false;

		makeObservable(this, {
			formStore: observable,
			isLoading: observable,
			isSuccess: observable,
			httpError: observable,
			data: observable,
			isSaved: observable,
			isEdited: observable,
			reset: action,
			setFormStore: action,
			setIsLoading: action,
			setIsSuccess: action,
			setHttpError: action,
			setData: action,
			setIsLocalCache: action,
			setIsSaved: action,
			setIsEdited: action,
			clearHttpError: action,
		});

		// TODO
		// observe(this.data, change => {
		// 	console.log(`TCL ~ change`, change.name);
		// });
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	abstract getCreateFormData(): TCreate;

	/**
	 * Must call isValid before calling this function
	 * @returns
	 */
	abstract getEditFormData(): TEdit;

	/**
	 * Check is valid data before edit or account-editor
	 */
	abstract isValid(): boolean;

	reset() {
		this.data = this.createInstance();
		this.formStore = new FormStore();
		this.isLoading = false;
		this.isSuccess = false;
		this.httpError = null;
	}

	createInstance() {
		return new this.model();
	}

	create(options?: IRequestOptions): Promise<T | { id: id } | void> {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			return new Promise(res => res());
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);

		return this.apiLayer
			.create(this.getCreateFormData(), options)
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch((error: IAskError) => {
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					this.setHttpError(
						this.errorModel ? new this.errorModel({ ...error.data, httpStatus: error.response?.status }) : (error as TError)
					);
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	async edit() {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			// TODO - add error toast?
			return;
		}

		const id = this.data.getId();
		if (!id) {
			this.setIsSuccess(false);
			return;
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);
		if (id) {
			// Typescript enforces me to put this if
			return this.apiLayer
				.edit(id.toString(), this.getEditFormData())
				.then(() => {
					runInAction(() => {
						this.setIsLoading(false);
						this.setIsSuccess(true);
					});
				})
				.catch(error => {
					if (this.onError) {
						this.onError(error?.data);
					}
					runInAction(() => {
						if (this.errorModel) {
							this.setHttpError(new this.errorModel({ ...error?.data, httpStatus: error?.response?.status }));
						}
						this.setIsLoading(false);
						this.setIsSuccess(false);
					});
				});
		}
	}

	get(id: id, options?: URLSearchParams): Promise<void | T> {
		if (this.getIsLocalCache()) {
			return Promise.resolve();
		}

		this.setIsLocalCache(true);
		this.setIsLoading(true);

		return this.apiLayer
			.get(id, options)
			.then(res => {
				const data = new this.model(res);
				const originData = new this.model(res);
				runInAction(() => {
					this.setIsLocalCache(false); //TODO: move to finally
					this.setData(data);
					this.setOriginData(originData);
					this.setIsLoading(false);
				});
				return data;
			})
			.catch(error => {
				console.log({ error });
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response?.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	delete(id: id): Promise<void | TError> {
		this.setIsLoading(true);
		this.setIsSuccess(false);
		return this.apiLayer
			.delete(id.toString())
			.then(() => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
			})
			.catch(error => {
				// TODO - log
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public getFormStore(): FormStore {
		return this.formStore;
	}

	public setFormStore(formStore: FormStore): void {
		this.formStore = formStore;
	}

	public getIsLoading(): boolean {
		return this.isLoading;
	}

	public setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	public setIsSuccess(isSuccess: boolean) {
		this.isSuccess = isSuccess;
	}

	public getIsSuccess(): boolean {
		return this.isSuccess;
	}

	public getHttpError(): TError | null {
		return this.httpError;
	}

	public setHttpError(httpError: TError | null) {
		this.httpError = httpError;
	}

	public clearHttpError() {
		this.httpError = null;
	}

	public setOriginData(data: T) {
		this.originData = data;
	}

	public getOriginData(): T {
		return this.originData;
	}

	public setData(data: T) {
		this.data = data;
	}

	public getData(): T {
		return this.data;
	}

	public setIsLocalCache(isLocalCache: boolean) {
		this.isLocalCache = isLocalCache;
	}

	public getIsLocalCache(): boolean {
		return this.isLocalCache;
	}

	public setIsSaved(isSaved: boolean) {
		this.isSaved = isSaved;
	}

	public getIsSaved() {
		return this.isSaved;
	}

	public setIsEdited(isEdited: boolean) {
		this.isEdited = isEdited;
	}

	public getIsEdited() {
		return this.isEdited;
	}
}

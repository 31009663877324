import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Home } from '../../pages/home/home.page';
import { Login } from '../../pages/login/login.page';
import routes from '../routes/index.routes';
import { AuthLayout } from '../components/auth-layout/auth-layout';
import Layout from '@monorepo/base/src/components/layout/layout';
import { RedirectAuth } from '../components/redirect-auth/redirect-auth';
import { RequireAuth } from '../components/require-auth/require-auth';
import { CampaignsRouter } from './campaigns.router';
import { PreferencesRouter } from './preferences.router';
import { CreativesRouter } from './creatives.router';
import { AdvertisersRouter } from './advertisers.router';
import { CampaignGroupsRouter } from './campaign-groups.router';
import { CreativeGroupsRouter } from './creative-groups.router';
import { TestsRouter } from './tests.router';
import { DealsRouter } from './deals.router';
import { JobsRouter } from './jobs.router';
import { ToolsRouter } from './tools.router';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { useEffect } from 'react';
import { IAdminxStore } from '../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { OAuthProviderEnum } from '../enums/oauth-providers';
import { OAuthLoginRedirect } from '../../pages/login/login-redirect';
import { TwoFactorAuth } from '../../pages/login/2fa.page';
import { Reports } from '../../pages/reports/index/reports.page';
import { DomainsRouter } from './domains.router';
import { KeysManagementRouter } from './keys-management.router';
import { AccountsRouter } from './accounts.router';
import { BidderRouter } from './bidder.router';

const Router = () => {
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { settingsStore, tooltipsStore } = useStores<IAdminxStore>();

	useEffect(() => {
		tooltipsStore.removeTooltips();
		if (!settingsStore.getPages().get(currentRouteWithoutSpecialChars)) {
			settingsStore.getPages().set(currentRouteWithoutSpecialChars, { tableStore: new TableStore() });
		}
	}, [currentRouteWithoutSpecialChars]);
	// Todo: continue here -> missing 2fa page
	const appRoutes = [
		// Unauthorized Routes
		{
			element: (
				<RedirectAuth>
					<Layout />
				</RedirectAuth>
			),
			children: [
				{
					path: routes.auth.twoFactor(),
					element: <TwoFactorAuth />,
				},
				{
					path: routes.auth.login(),
					element: <Login />,
				},
			],
		},
		{
			path: routes.auth.redirect(OAuthProviderEnum.Google),
			element: <OAuthLoginRedirect provider={OAuthProviderEnum.Google} />,
		},
		// Authorized Routes
		{
			element: (
				<RequireAuth>
					<AuthLayout />
				</RequireAuth>
			),
			children: [
				{
					path: routes.general.home(),
					element: <Home />,
				},
				{
					path: routes.reports.index(),
					element: <Reports />,
				},
				...CampaignsRouter,
				...PreferencesRouter,
				...CreativesRouter,
				...AdvertisersRouter,
				...CampaignGroupsRouter,
				...CreativeGroupsRouter,
				...TestsRouter,
				...DealsRouter,
				...DomainsRouter,
				...JobsRouter,
				...ToolsRouter,
				...KeysManagementRouter,
				...AccountsRouter,
				...BidderRouter,
			],
		},
		// Wildcard
		{
			path: '*',
			element: (
				<RequireAuth>
					<Home />
				</RequireAuth>
			),
		},
	];

	return useRoutes(appRoutes);
};
export const AdminXRouter = () => {
	const didMount = useDidMount();
	if (!didMount) {
		return <BarLoader is={true} />;
	}

	return (
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	);
};

import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './sidebar.module.scss';
import { Logo } from '@monorepo/base/src/components/logo/logo';
import { Sidebar, SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { route } from '@monorepo/tools/src/lib/types/url';
import routes from '../../routes/index.routes';
import { observer } from 'mobx-react';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { AdminxLogo } from '../logo/logo';

interface IItemList {
	mini?: boolean;
	title?: JSX.Element | string;
	route: string; // TODO - one of the routes
	icon: string;
	color?: string;
}

// TODO - @MFP remove it from controlled
export const ControlledSidebar = observer(() => {
	const { sidebarStore } = useStores<IControlledStore>();
	const { indexRoute } = useRoute();
	const [activeRoute, setActiveRoute] = useState<route>(indexRoute || routes.general.home());

	useEffect(() => {
		if (indexRoute) {
			setActiveRoute(indexRoute);
			sidebarStore.setSidebarOpen(true);
		}
	}, [indexRoute]);

	const ItemList = ({ mini = false, title, route, icon, color }: IItemList) => {
		return (
			<li className={`${styles.itemWrapper} ${activeRoute === route ? styles.activeItem : ''}`}>
				<LinkWithParams to={route} onClick={() => setActiveRoute(route)} className={styles.itemLink}>
					<div className={styles.item}>
						<Icon
							className={styles.icon}
							isMFP={true}
							color={color}
							size={'18px'}
							// font={activeRoute === route ? IconFonts.Filled : IconFonts.Outlined}
						>
							{icon}
						</Icon>
						{mini ? null : <span className={styles.itemTitle}>{title}</span>}
					</div>
				</LinkWithParams>
			</li>
		);
	};

	// !DEPRECATED
	// const onClickSidebarButton = () => {
	// 	if (devices.isBigRes) {
	// 		sidebarStore.setSidebarAnimate(true);
	// 		sidebarStore.setSidebarOpen(!sidebarStore.getIsSideBarOpen());
	// 		sidebarStore.setSidebarMode(SidebarMode.LOCK);
	// 	} else {
	// 		sidebarStore.setSidebarVisibility(!sidebarStore.getIsSideBarVisible());
	// 	}
	// };

	const isMiniSidebar = (isHover: boolean) => {
		return (
			(!isHover && sidebarStore.getSidebarMode() === SidebarMode.HOVER) ||
			(!sidebarStore.getIsSideBarOpen() && sidebarStore.getSidebarMode() === SidebarMode.LOCK)
		);
	};

	return (
		<Sidebar
			isOpen={sidebarStore.getIsSideBarOpen()}
			isAnimate={sidebarStore.getIsSideBarAnimate() || sidebarStore.getSidebarMode() === SidebarMode.HOVER}
			mode={sidebarStore.getSidebarMode()}>
			{props => {
				const { isHover } = props;
				const _isMiniSidebar = isMiniSidebar(isHover);
				return (
					<div className={styles.wrapper}>
						<div className={styles.toggler}>
							<Logo text={!_isMiniSidebar ? 'AdminX' : ''} to={routes.general.home()}>
								<AdminxLogo />
							</Logo>
						</div>
						<ul className={`${styles.list} ${styles.fullList}`}>
							<ItemList route={routes.general.home()} icon={'bar-chart-square-02'} title={'Overview'} mini={_isMiniSidebar} />
							<ItemList
								route={routes.reports.index()}
								icon={'bar-chart-12'}
								title={'Dynamic Reports'}
								mini={_isMiniSidebar}
							/>
							<ItemList route={routes.advertisers.index()} icon={'building-07'} title={'Advertisers'} mini={_isMiniSidebar} />
							<ItemList
								route={routes.campaignGroups.index()}
								icon={'dataflow-04'}
								title={'Campaign Groups'}
								mini={_isMiniSidebar}
							/>
							<ItemList route={routes.campaigns.index()} icon={'announcement-01'} title={'Campaigns'} mini={_isMiniSidebar} />
							<ItemList
								route={routes.creativeGroups.index()}
								icon={'layers-three-02'}
								title={'Creative Groups'}
								mini={_isMiniSidebar}
							/>
							<ItemList route={routes.creatives.index()} icon={'image-01'} title={'Creatives'} mini={_isMiniSidebar} />
							<ItemList route={routes.preferences.index()} icon={'settings-01'} title={'Preferences'} mini={_isMiniSidebar} />
							{/* <ItemList route={routes.tests.index()} icon={'quiz'} title={'Test Group'} mini={_isMiniSidebar} /> */}
							<ItemList route={routes.tests.index()} icon={'paint'} title={'Tests'} mini={_isMiniSidebar} />
							<ItemList route={routes.deals.index()} icon={'wallet-04'} title={'Deals'} mini={_isMiniSidebar} />
							<ItemList route={routes.domains.index()} icon={'at-sign'} title={'Domains'} mini={_isMiniSidebar} />
							<ItemList route={routes.jobs.index()} icon={'alarm-clock'} title={'Jobs'} mini={_isMiniSidebar} />
							<ItemList
								route={routes.tools.tool('replace-cids')}
								icon={'tool-01'}
								title={'CID Replacement'}
								mini={_isMiniSidebar}
							/>
							<ItemList
								route={routes.keysManagement.index()}
								icon={'alarm-clock'}
								title={'Keys Management'}
								mini={_isMiniSidebar}
							/>
							<ItemList route={routes.bidder.campaigns()} icon={'magic-wand-01'} title={'Bidder'} mini={_isMiniSidebar} />
							<ItemList route={routes.accounts.index()} icon="users-03" title="Accounts" mini={_isMiniSidebar} />
						</ul>
					</div>
				);
			}}
		</Sidebar>
	);
});

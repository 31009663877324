import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import routes from '../../../modules/routes/index.routes';
import { Outlet } from 'react-router-dom';
import { TableDefaultButton } from '../../../modules/components/report-table/table-elements/table-buttons';
import ReportTable from '../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'keys_management_pagebar')] };

export const Accounts: FC = observer(() => {
	const {
		accountsStore: { accountsReportTable },
	} = useStores<IAdminxStore>();

	useTableStore({ reportTableStore: accountsReportTable });

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Accounts</PagebarTitle>
			</Pagebar>

			<div className="table-wrapper">
				<ReportTable tableStore={accountsReportTable} disableCsvExport showSearchFilter={false} showColumnPicker={false}>
					<TableDefaultButton to={routes.accounts.create()} />
				</ReportTable>
			</div>

			<Outlet />
		</Page>
	);
});

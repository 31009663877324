import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useCampaignTableOfContents = () => {
	const [isTableOfContents, setIsTableOfContents] = useState<boolean>(false);
	const location = useLocation();

	const [tableOfContents] = useState([
		{ id: 'basicInfo', element: 'Basic Info', display: true },
		{ id: 'pretargeting', element: 'Pretargeting', display: true },
		{ id: 'dailyBudget', element: 'Daily Budget', display: true },
		{ id: 'defaultPayout', element: 'Default Payout', display: true },
		{ id: 'targeting', element: 'Targeting', display: true },
		{ id: 'maxLossPerDay', element: 'Maximum Loss', display: true },
		{ id: 'campaignCreatives', element: 'Creatives', display: true },
		{ id: 'rules', element: 'Rules', display: true },
		{ id: 'sitesInTargetInfo', element: 'Sites In Target', display: true },
		{ id: 'bidKeysInfo', element: 'Bidkeys Info', display: true },
		{ id: 'sitesInTarget', element: 'Keys In Target', display: true },
	]);

	useEffect(() => {
		return () => setIsTableOfContents(false);
	}, [location]);

	return {
		isTableOfContents,
		tableOfContents: tableOfContents.filter(({ display }) => display).map(({ id, element }) => ({ id, element })),
		setIsTableOfContents,
	};
};

import { ReactNode, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import styles from './menu.module.scss';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

export interface IMenu {
	isOpen: boolean;
	children: ReactNode;
	onClose?: (e?: Event) => void;
	alignToRight?: boolean;
	alignToLeft?: boolean;
	fromTop?: boolean;
	unstyled?: boolean;
	className?: string;
	debugProps?: IDebugProps;
}

export const Menu = (props: IMenu) => {
	const { isOpen, children, onClose, alignToRight, alignToLeft, fromTop, debugProps, unstyled = false, className = '' } = props;
	const [isOpenMenu, setMenu] = useState<boolean>(isOpen);
	const menuRef = useRef<HTMLDivElement>(null);
	const [positionClass, setPositionClass] = useState<string>('');
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setMenu(isOpen);
	}, [isOpen]);

	useOnClickOutside(menuRef, (e) => {
		if (onClose) {
			onClose(e);
		}
		setMenu(false);
	});

	useEffect(() => {
		if (!positionClass && menuRef.current) {
			const menuRect = menuRef.current.getBoundingClientRect();
			if (alignToRight || alignToLeft) {
				if (alignToRight) {
					setPositionClass(styles.alignToRight);
				} else if (alignToLeft) {
					setPositionClass(styles.alignToLeft);
				}
				return;
			}

			if (menuRect && menuRect.x + menuRect.width + 80 > window.innerWidth) {
				setPositionClass(styles.alignToRight);
			} else if (menuRect && menuRect.x < 0) {
				setPositionClass(styles.alignToLeft);
			} else {
				setPositionClass(styles.normalAlign);
			}
		}
	}, [menuRef.current]);

	return (
		<div ref={menuRef} className={`${styles.wrapper} ${isOpenMenu ? styles.open : styles.close}`}>
			{isOpenMenu ? (
				<div
					{...generateDataAttrs(dataAttrs || [new DataAttribute('id', 'menu')])}
					className={`${unstyled ? styles.unstyled : styles.menu} ${positionClass} ${
						fromTop ? styles.fromTop : ''
					} ${className}`}>
					{children}
				</div>
			) : null}
		</div>
	);
};

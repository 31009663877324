import { makeAutoObservable } from 'mobx';
import {
	ReportParams,
	ReportResponse as IReportResponse,
	generateReportParams,
	ReportFilter,
	ReportDateSegments,
	ReportSortOrder,
} from './old-report.types';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { AdminxReporterApi } from '../../../../apis/adminx-reporter.api';
import { IDataStore } from './data-store.types';

export class ReportResponse<T> {
	public success: boolean;
	public errorMessage: string | null;
	public data: T[];
	public total: number;
	public limited: boolean;
	public footer: T;
	public columns: string[][];
	public graphData: object;

	constructor(reportResponse: IReportResponse<T>) {
		this.success = reportResponse.success;
		this.errorMessage = reportResponse.errorMessage;
		this.data = reportResponse.data;
		this.total = reportResponse.total;
		this.limited = reportResponse.limited;
		this.footer = reportResponse.footer;
		this.columns = reportResponse.columns;
		this.graphData = reportResponse.graphData;
		makeAutoObservable(this);
	}

	public getFooter(): T {
		return this.footer;
	}

	public getData(): T[] {
		return this.data;
	}
}

interface IReportSroreParams {
	reportParams: Partial<ReportParams>;
	isGraphAvailable: boolean;
}

class ReportStore<T> implements IDataStore<T, ReportParams> {
	reportParams = generateReportParams();
	defaultReportParams: ReportParams;
	hiddenFilters: ReportFilter[] = []; // hidden-filters
	isGraphAvailable: boolean;
	report = new HttpStore<ReportParams, ReportResponse<T>>({
		httpFunc: AdminxReporterApi.newReport,
		model: ReportResponse<T>,
	});
	graph?: HttpStore<ReportParams, ReportResponse<T>>;
	downloadCsv = new HttpStore<ReportParams, Blob | null>({
		httpFunc: AdminxReporterApi.downloadCsv,
	});

	constructor({ reportParams, isGraphAvailable }: IReportSroreParams) {
		this.reportParams = { ...this.reportParams, ...reportParams };
		this.defaultReportParams = JSON.parse(JSON.stringify(this.reportParams));
		this.isGraphAvailable = isGraphAvailable;
		if (isGraphAvailable) {
			this.graph = new HttpStore<ReportParams, ReportResponse<T>>({
				httpFunc: AdminxReporterApi.newReport,
				model: ReportResponse<T>,
			});
		}
		makeAutoObservable(this);
	}

	async fetchReport({ params, appendData = false }: { params: Partial<ReportParams>; appendData?: boolean }) {
		const isParamsChanged = this.isParamsAreDifferent(params);
		if (isParamsChanged) {
			this.report.abort();
			this.graph?.abort();
			const reportFilters = this.hiddenFilters.concat(params.filters || []);
			this.reportParams = { ...this.reportParams, ...params, filters: reportFilters };
			this.reportParams.dateSegment = this.reportParams.groupBys.includes('dt') ? ReportDateSegments.Daily : ReportDateSegments.None;
			const graphParams = {
				...this.reportParams,
				dateSegment: ReportDateSegments.Daily,
				groupBys: ['dt'],
				sortBy: 'date',
				sortOrder: ReportSortOrder.Desc,
			};
			const promises = [
				this.report.fetch(this.reportParams, undefined, { appendData }),
				this.graph?.fetch(graphParams, undefined, { appendData: false }),
			];
			await Promise.allSettled(promises);
		}
	}

	async downloadReportFileCsv(reportFileName = 'report') {
		const fileName = `adminx ${reportFileName} ${this.reportParams.periodStart} - ${this.reportParams.periodEnd}`;
		const totalRows = this.getTotalRows();
		if (totalRows <= 0) {
			return;
		}
		this.downloadCsv.fetch(this.reportParams).then((blob: Blob | null) => {
			if (blob !== null) {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileName}.csv`);
				link.style.display = 'none';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		});
	}

	getReportData(): T[] {
		return this.report.getData()?.getData() || [];
	}

	getReportFooter(): T {
		return this.report.getData()?.getFooter() || ({} as T);
	}

	getTotalRows() {
		return this.report.getData()?.total || 0;
	}

	getIsLoading() {
		return this.report.getIsLoading();
	}

	getError() {
		return this.report.getHttpError();
	}

	setHiddenFilters(filters: ReportFilter[]) {
		// reset report and graph store if filters are changed
		if (
			filters.length !== this.hiddenFilters.length ||
			!filters.every((val, index) => JSON.stringify(val) === JSON.stringify(this.hiddenFilters[index]))
		) {
			this.report.reset();
			this.graph?.reset();
		}
		this.hiddenFilters = filters;
	}

	isParamsAreDifferent(partialParams: Partial<ReportParams>, params: ReportParams = this.reportParams): boolean {
		// Check each property explicitly if it exists in partialParams
		if (partialParams.start !== undefined && partialParams.start !== params.start) {
			return true;
		}
		if (partialParams.limit !== undefined && partialParams.limit !== params.limit) {
			return true;
		}
		if (partialParams.periodStart !== undefined && partialParams.periodStart !== params.periodStart) {
			return true;
		}
		if (partialParams.periodEnd !== undefined && partialParams.periodEnd !== params.periodEnd) {
			return true;
		}
		if (partialParams.multipleTimePeriods !== undefined) {
			if (
				partialParams.multipleTimePeriods.length !== params.multipleTimePeriods.length ||
				!partialParams.multipleTimePeriods.every(
					(val, index) => JSON.stringify(val) === JSON.stringify(params.multipleTimePeriods[index])
				)
			) {
				return true;
			}
		}
		if (partialParams.filters !== undefined) {
			const partialFilters = partialParams.filters.filter(
				val => !this.hiddenFilters.some(defaultFilter => JSON.stringify(defaultFilter) === JSON.stringify(val))
			);
			const filters = params.filters.filter(
				val => !this.hiddenFilters.some(defaultFilter => JSON.stringify(defaultFilter) === JSON.stringify(val))
			);
			if (
				partialFilters.length !== filters.length ||
				!partialFilters.every(val => filters.some(filter => JSON.stringify(val) === JSON.stringify(filter)))
			) {
				return true;
			}
		}
		if (partialParams.groupBys !== undefined) {
			if (
				partialParams.groupBys.length !== params.groupBys.length ||
				!partialParams.groupBys.every((val, index) => val === params.groupBys[index])
			) {
				return true;
			}
		}
		if (partialParams.dateSegment !== undefined && partialParams.dateSegment !== params.dateSegment) {
			return true;
		}
		if (partialParams.sortBy !== undefined && partialParams.sortBy !== params.sortBy) {
			return true;
		}
		if (partialParams.sortOrder !== undefined && partialParams.sortOrder !== params.sortOrder) {
			return true;
		}
		if (partialParams.inResultsSort !== undefined && partialParams.inResultsSort !== params.inResultsSort) {
			return true;
		}
		if (partialParams.inResultsSortOrder !== undefined && partialParams.inResultsSortOrder !== params.inResultsSortOrder) {
			return true;
		}
		if (partialParams.inResultsFilters !== undefined) {
			if (
				partialParams.inResultsFilters.length !== params.inResultsFilters.length ||
				!partialParams.inResultsFilters.every(
					(val, index) => JSON.stringify(val) === JSON.stringify(params.inResultsFilters[index])
				)
			) {
				return true;
			}
		}
		if (partialParams.graphYaxis !== undefined && partialParams.graphYaxis.length !== params.graphYaxis.length) {
			return true;
		}
		if (partialParams.graphLines !== undefined && partialParams.graphLines.length !== params.graphLines.length) {
			return true;
		}
		if (partialParams.additionalColumns !== undefined && partialParams.additionalColumns.length !== params.additionalColumns.length) {
			return true;
		}
		if (partialParams.reportName !== undefined && partialParams.reportName !== params.reportName) {
			return true;
		}
		if (partialParams.submit !== undefined && partialParams.submit !== params.submit) {
			return true;
		}
		if (partialParams.type !== undefined && partialParams.type !== params.type) {
			return true;
		}

		return false;
	}
}

export default ReportStore;

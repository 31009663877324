import routes from '../routes/index.routes';
import { Accounts } from '../../pages/accounts/index/accounts.page';
import { AccountEditor } from '../../pages/accounts/account-editor/account-editor.page';

export const AccountsRouter = [
	{
		path: routes.accounts.index(),
		element: <Accounts />,
		children: [
			{
				path: routes.accounts.edit(':accountId'),
				element: <AccountEditor />,
			},
			{
				path: routes.accounts.create(),
				element: <AccountEditor />,
			},
		],
	},
];

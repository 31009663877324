export type SelectOptions = {
	label: string | number;
	value: string | number;
	to?: string;
};

import { useEffect, useRef, useState } from 'react';
import { Input } from '../form/input/input';
import { Icon } from '../icon/icon';
import { List, ListItem } from '../list/list';
import styles from './select-new.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Divider } from '../divider/divider';
import { Skeleton } from '../skeleton/skeleton';
import { LinkWithParams } from '../link-with-params/link-with-params';

interface ISelect {
	options: SelectOptions[];
	isSearch?: boolean;
	preserveOrder?: boolean;
	placeholder?: string;
	onSelect?: (value: (string | number)[]) => void;
	disabled?: boolean;
	defaultValues?: (string | number)[];
	multiple?: boolean;
	classNames?: {
		wrapper?: string;
		listItem?: string;
		list?: string;
		autocompleteInput?: string;
	};
	isSelectAll?: boolean;
	debugProps?: IDebugProps;
	minSelections?: number;
	maxSelections?: number;
	required?: boolean;
	isLoading?: boolean;
	sortBy?: (a: any, b: any) => number;
}

export const MIN_LENGTH_FOR_SEARCH = 6;

export const SelectNew = (props: ISelect) => {
	const {
		classNames,
		placeholder,
		preserveOrder,
		options,
		onSelect = null,
		disabled = false,
		defaultValues,
		multiple,
		isSearch = true,
		debugProps,
		minSelections = 0,
		maxSelections,
		required,
		isLoading,
		sortBy,
	} = props;

	const inputElement = useRef<HTMLInputElement>(null);
	const [filteredOptions, setFilteredOptions] = useState<SelectOptions[]>(sortBy ? options.sort(sortBy) : options);
	const [selectedOptions, setSelectedOptions] = useState<Set<string | number>>(new Set(defaultValues || []));
	const [autocompleteValue, setAutocompleteValue] = useState<string>('');
	const [isFocus, setIsFocus] = useState<boolean>(true);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setFilteredOptions(sortBy ? options.sort(sortBy) : options);
	}, [options]);

	const hasIntersection = () => {
		const selectedOptionsArr = Array.from(selectedOptions);
		for (let i = 0; i < selectedOptionsArr.length; i++) {
			for (let j = 0; j < filteredOptions.length; j++) {
				if (selectedOptionsArr[i] === filteredOptions[j].value) {
					return true;
				}
			}
		}
		return false;
	};

	const onListItemClick = (_option: SelectOptions) => {
		const newSelectedOptions = new Set([...selectedOptions]);

		if (multiple) {
			if (newSelectedOptions.has(_option.value) && newSelectedOptions.size > minSelections) {
				if (!required) {
					newSelectedOptions.delete(_option.value);
				} else if (newSelectedOptions.size > 1) {
					newSelectedOptions.delete(_option.value);
				}
			} else if (newSelectedOptions.size < (maxSelections || options.length)) {
				newSelectedOptions.add(_option.value);
			}
		} else {
			if (!newSelectedOptions.has(_option.value) || required) {
				newSelectedOptions.clear();
				newSelectedOptions.add(_option.value);
			} else {
				newSelectedOptions.clear();
			}
		}

		setSelectedOptions(new Set(newSelectedOptions));

		onSelect && onSelect(Array.from(newSelectedOptions));
	};

	const onSelectAll = () => {
		const newSelectedOptions = new Set<string | number>(filteredOptions.map(_option => _option.value));
		setSelectedOptions(newSelectedOptions);
		onSelect && onSelect(Array.from(newSelectedOptions));
	};

	const clearAll = () => {
		setSelectedOptions(new Set<string | number>());
		onSelect && onSelect([]);
	};

	const renderOptions = () => {
		if (isLoading) {
			return <Skeleton fullWidth is rectanglesAmount={6} />;
		}

		if (!filteredOptions || (filteredOptions.length === 0 && autocompleteValue)) {
			return (
				<div className={styles.empty}>
					<span>No matches found.</span>
					<span>Check spelling or try different terms.</span>
				</div>
			);
		}

		return (
			<List className={`${styles.list} ${classNames?.list}`} {...generateDataAttrs(suffixToDataAttr('_list', dataAttrs))}>
				{!preserveOrder && Array.from(selectedOptions).map(selectedOption =>
					filteredOptions
						.filter(_filteredOption => selectedOption === _filteredOption.value)
						.map(_selectedOption => (
							<ListItem
								onClick={() => {
									onListItemClick(_selectedOption);
								}}
								className={`${styles.listItem} ${classNames?.listItem} ${styles.selected}`}
								key={_selectedOption.label}>
								<span className={styles.label}>{_selectedOption.label}</span>
								<Icon className={styles.checkIcon} isMFP={true} color={getTheme().primary600}>
									check
								</Icon>
							</ListItem>
						))
				)}
				{multiple && selectedOptions.size > 0 && filteredOptions.length > 0 && hasIntersection() && (
					<div className={styles.separatorLine}>
						<Divider />
					</div>
				)}

				{filteredOptions
					.filter((option) => preserveOrder || !selectedOptions.has(option?.value))
					.map((option) => (
						<ListItem
							onClick={() => onListItemClick(option)}
							className={`${styles.listItem} ${classNames?.listItem} ${
								selectedOptions.has(option.value) ? styles.selected : ''
							}`}
							key={option.label}>
							{option.to ? (
								<LinkWithParams to={option.to} className={styles.link} onClick={e => e.stopPropagation()}>
									<span className={styles.label}>{option.label}</span>
								</LinkWithParams>
							) : (
								<span className={styles.label}>{option.label}</span>
							)}
							{selectedOptions.has(option?.value) && (
								<Icon className={styles.checkIcon} isMFP={true} color={getTheme().primary600}>
									check
								</Icon>
							)}
						</ListItem>
					))}
			</List>
		);
	};

	const renderLimit = () => {
		if (isLoading) {
			return null;
		}
		return `${selectedOptions.size}/${maxSelections || options.length}`;
	};

	return (
		<div className={`${styles.wrapper} ${classNames?.wrapper}`}>
			{options.length < MIN_LENGTH_FOR_SEARCH || !isSearch ? null : (
				<Input
					ref={inputElement}
					inline={true}
					autoFocus={true}
					onFocusEvent={_isFocus => setIsFocus(_isFocus)}
					icon={'search-sm'}
					iconColor={isFocus ? getTheme().primaryColor : getTheme().gray500}
					className={`${styles.inputWrapper}`}
					inputClassName={classNames?.autocompleteInput}
					removeLabel={true}
					value={autocompleteValue}
					placeholder={placeholder}
					onValue={inputValue => {
						setFilteredOptions(
							options.filter(options => (options.label.toString() || '').toLowerCase().includes(inputValue.toLowerCase()))
						);
						setAutocompleteValue(inputValue);
					}}
					disabled={disabled}
					onSelect={e => e.stopPropagation()}
					debugProps={{ dataAttrs: suffixToDataAttr('_search', dataAttrs) }}
					animate={false}
				/>
			)}
			{renderOptions()}
			{(multiple || !required) && (
				<div className={styles.multipleInfoAndActions}>
					<div className={styles.multipleActions}>
						{(!maxSelections || maxSelections === options.length) && multiple && !isLoading && (
							<div className={styles.selectAll} onClick={() => onSelectAll()}>
								Select All
							</div>
						)}
						{!required && !isLoading && (
							<div className={styles.clearAll} onClick={() => clearAll()}>
								Clear
							</div>
						)}
					</div>
					{multiple && <div className={styles.limit}>{renderLimit()}</div>}
				</div>
			)}
		</div>
	);
};

import { makeAutoObservable, observable } from 'mobx';
import { AccountsCrudStore } from './accounts-crud.store';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { accountsTableConfig } from '../../components/report-table/configurations/adx-repr/accounts/accounts.config';

export class AccountsStore {
	crud = new AccountsCrudStore();
	accountsReportTable = new NewAdminxReportTableStore(accountsTableConfig);

	constructor() {
		makeAutoObservable(this, {
			accountsReportTable: observable,
		});
	}

	getCrud() {
		return this.crud;
	}
}

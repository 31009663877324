import { OnChangeFn, PaginationState, RowSelectionState, SortingState, VisibilityState } from '@tanstack/react-table';
import { ReportFilter } from '../data-stores/old-report.types';
import { ITableState, TableState } from '../../table-state.store';
import { makeObservable, observable } from 'mobx';
import { IDataStore, IDataType } from '../data-stores/data-store.types';
import { ColumnVisibilityOptions } from '../../columns/columns.types';
import { ColumnConfig } from '../../columns/columns';

export interface ITableStoreParams<T> {
	columns: ColumnConfig[];
	tableState?: Partial<Omit<ITableState, 'tableName'>>;
	tableName: string;
	dataStore: IDataStore<T>;
}

export class TableStore<T = IDataType, P = unknown> {
	_columns: ColumnConfig[];
	_tableState: TableState;
	_dataStore: IDataStore<T, P>;

	constructor({ columns, tableState, tableName, dataStore }: ITableStoreParams<T>) {
		this._columns = columns;
		this._dataStore = dataStore;
		this._tableState = new TableState({
			pagination: {
				pageIndex: 0,
				pageSize: 100,
			},
			sorting: [],
			filters: [],
			columnVisibility: tableState?.isSpeedyModeOn ? this.getSpeedyModeColumnVisibility() : this.getDefaultColumnVisibility(),
			rowSelection: {},
			...tableState,
			tableName,
		});
		makeObservable(this, {
			_columns: observable,
			_tableState: observable,
			_dataStore: observable,
		});
	}

	setHiddenFilters = (filters: ReportFilter[]) => {
		this.dataStore.setHiddenFilters(filters);
	};

	updateTableState(newTableState: Partial<ITableState>) {
		this.tableState.updateTableState(newTableState);
	}

	getDefaultColumnVisibility() {
		return this.columns.reduce((acc, column) => {
			if (column.visibility === ColumnVisibilityOptions.Visible) {
				acc[column.accessor] = true;
			} else if (column.visibility === ColumnVisibilityOptions.Hidden) {
				acc[column.accessor] = false;
			}
			return acc;
		}, {} as VisibilityState);
	}

	onSegmentChange = (segment: string) => {
		this.updateTableState({ segment });
	};

	onSortingChange: OnChangeFn<SortingState> = sorting => {
		const newSorting = typeof sorting === 'function' ? sorting(this.tableState.sorting) : sorting;
		this.updateTableState({ sorting: newSorting });
	};

	onColumnVisibilityChange: OnChangeFn<VisibilityState> = columnVis => {
		const columnVisibility = typeof columnVis === 'function' ? columnVis(this.tableState.columnVisibility) : columnVis;
		this.updateTableState({ columnVisibility });
	};

	onPaginationChange: OnChangeFn<PaginationState> = pagination => {
		const newPagination = typeof pagination === 'function' ? pagination(this.tableState.pagination) : pagination;
		this.updateTableState({ pagination: newPagination });
	};

	onRowSelectionChange: OnChangeFn<RowSelectionState> = rowSelection => {
		const newRowSelection = typeof rowSelection === 'function' ? rowSelection(this.tableState.rowSelection) : rowSelection;
		this.updateTableState({ rowSelection: newRowSelection });
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getRowId(row: any) {
		// default table key, all the not null values of the row joined by '@'
		return Object.values(row)
			.filter(v => v !== null)
			.join('@');
	}

	getSelectedRows() {
		const rowSelection = this.tableState.rowSelection;
		const selectedRowsTableId = Object.keys(rowSelection).filter(rowId => rowSelection[rowId]);
		return this.dataStore?.getReportData().filter(row => selectedRowsTableId.includes(this.getRowId(row)));
	}

	onFilterChange(filters: ReportFilter[]) {
		this.updateTableState({ filters });
	}

	fetchReport(params: Partial<P>, appendData = false) {
		this.dataStore.fetchReport({ params, appendData });
	}

	getMetrcisHeaders() {
		return this.columns.filter(col => col.type === 'metrics').map(col => col.header);
	}

	downloadCsv = (): void => {
		if (this.dataStore.downloadReportFileCsv) {
			this.dataStore.downloadReportFileCsv();
		} else {
			throw new Error('Not implemented');
		}
	};

	getSpeedyModeColumnVisibility = () => {
		return this.columns.reduce((acc, col) => {
			acc[col.accessor] = col.inSpeedyMode ? true : false;
			return acc;
		}, {} as VisibilityState);
	};

	toggleSpeedyModeColumnVisibility = (isOn: boolean) => {
		let columnVis: VisibilityState;
		if (isOn) {
			columnVis = this.getSpeedyModeColumnVisibility();
		} else {
			columnVis = this.columns.reduce((acc, col) => {
				if (!col.isAlwaysHiddenOrVisible()) {
					acc[col.accessor] = col.isVisible();
				}
				return acc;
			}, {} as VisibilityState);
		}
		this.onColumnVisibilityChange(columnVis);
	};

	get columns() {
		return this._columns;
	}

	set columns(value: ColumnConfig[]) {
		this._columns = value;
	}

	get tableState() {
		return this._tableState;
	}

	set tableState(value: TableState) {
		this._tableState = value;
	}

	get dataStore(): IDataStore<T> {
		return this._dataStore;
	}

	set dataStore(value: IDataStore<T>) {
		this._dataStore = value;
	}
}
